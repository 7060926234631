import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonDataModel, ProcessDataModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProcessorService {
  readonly gtUrl = `${environment.igpsBase.apiUrl}`;
  readonly staffingUrl = `${environment.staffingBaseUrl.apiUrl}`;

  constructor(private httpClient: HttpClient) { }

  async getAllProcessor() : Promise<CommonDataModel[]> {
    return await firstValueFrom(this.httpClient.get<CommonDataModel[]>(`${this.gtUrl}/gentask/v1/task/`));
  }

  async getAllUserProcess(staffId: string) : Promise<ProcessDataModel[]> {
    return await firstValueFrom(this.httpClient.get<ProcessDataModel[]>(`${this.staffingUrl}/staff/v1/${staffId}/getAllUserProcess`));
  }
}
