<igps-lib-spinner [showSpinner]="showSpinner"></igps-lib-spinner>
<lib-toast></lib-toast>
<section class="card grid-container">
  <section>
    <span class="callout-header">Welcome back, {{calloutName}}</span>
  </section>
  <section class="ds-grid-end">
    <section class="section-select">
      <mat-select id="selectProcessor" class="ig2" placeholder="-- Select --"
      [(value)]="selectedProcessId"
      (selectionChange)="onSelectionChange($event)">
        <mat-option [value]="item.processID" *ngFor="let item of processorList">
            {{item.processName}}
        </mat-option>
    </mat-select>
    </section>
    <section class="section-button">
      <button class="side-button" (click)="togglePanel()">
        <lib-icon [name]="toggleIconName" size="condensed"></lib-icon>
      </button>
    </section>
  </section>
</section>