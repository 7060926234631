export enum CommonMessages {
    Success = 'Your changes are saved',
    Error = 'Your changes are not saved',
    NoChanges='No changes to save',
    UnsavedChangesMessage = 'You have unsaved work that has not been submitted. Would you like to continue?',
    DeactivateRecordMessage='Deactivating the record will deactivate all the linked records. Do you want to go ahead with the same?',
    IGPSApiServerErrorMessage = 'Internal server error in IGPS API. Please re-try or report the incident if the issue persists.',
    InternalServerErrorMessage = 'Internal server error. Please re-try or report the incident if the issue persists.',
    ExceptionMessage = 'Server operation failed. Please re-try or report the incident if the issue persists.',
    TextInfoMessage = 'Process Name',
    DataChangeMessage = 'You may get error, as selected Region or Type is different than with loaded data.',
    SearchInputPlaceholder = 'Search by File Number, Property Address, Mortgagor Name, Loan Number'
}

export function mockUserInfo() {
    return {
        "id": "0e8f5a4d-e844-4ec0-b37e-fe0e083ec24a",
        "basicInfo": {
            "email": "mfarazuddin@firstam.com",
            "idaasId": null,
            "fastUserId": 171927,
            // "userName": "INTL\\mfarazuddin",
            "firstName": "Mohd",
            "lastName": "Farazuddin",
            "nickName": ""
        },
        "hireInfo": {
            "hireDate": null,
            "attritionDate": null,
            "returnDate": null,
            "attritionReason": ""
        },
        "site": {
            "id": "25f61d78-16da-40c9-8f58-d6d5483a92b5",
            "name": "Bangalore",
            "dateFormatId": 1,
            "dateFormat": "dd/mm/yyyy"
        },
        "productionSystem": {
            "id": "090cfac0-e920-48e5-b836-83ee31bf12d9",
            "name": "EPS"
        },
        "dedicatedProcess": {
            "processId": "b88410d2-6916-44c9-bcc1-134af353fbcc",
            "name": "New process Rachit"
        },
        "supervisor": null,
        "shift": null,
        "notes": "",
        "flags": {
            "isUserAdmin": true,
            "isSystemAdmin": true
        },
        "role": {
            "id": 750,
            "name": "SUPERVISOR"
        },
        "status": {
            "id": 1,
            "name": "ACTIVE"
        },
        "isUserPresentInFast": true,
        "createdBy": {
            "id": "ab6a5156-d5f3-4aeb-9833-f3f3e39251f2",
            "name": "Sowmya N Nambissan"
        },
        "lastUpdatedBy": null,
        "createDateUTC": "2022-07-13T10:14:12.207Z",
        "lastUpdatedDateUTC": null
    }
}