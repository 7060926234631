import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { getUserInfo, getUser, showSidebar, hideSidebar, isSidebarOpen, ws_sendMessage, wsStatus$ } from '@igps/client-root-config';
import { FaIconsRegistry, faMenuLeft, faMenuRight } from '@ui-core/icon';
import { ToastComponent } from '@ui-core/toast';
import { ErrorModel, ProcessDataModel, UserInfo } from './models';
import { ProcessorService } from './services/processor.service';
import { LoggerService } from './services/logger.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonMessages } from 'src/_mock_/mock';
import { selectedTaskId, notesClickEvent, onChangeProcess } from '@igps/top-utility';
import { Payload } from './models/wsSendMessage';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-dashboard-header',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'dashboard-header-ui';

  calloutName!: string;
  userInfo!: UserInfo;
  isFileUtilityOpen!: boolean;
  toggleIconName: string = 'menu_left';

  processorList!: ProcessDataModel[];
  selectedProcessId!: string;

  selectedTaskId: string | null = null;

  notesClickSubscription!: Subscription;
  wsStatusSubscription!: Subscription;

  @ViewChild(ToastComponent) toaster!: ToastComponent;
  showSpinner: boolean = false;

  constructor(
    private faIconsRegistry: FaIconsRegistry,
    private processorService: ProcessorService,
    private loggerService: LoggerService,
    private cdref: ChangeDetectorRef
  ) {
    this.faIconsRegistry.registerIcons([faMenuLeft, faMenuRight]);
  }

  ngOnDestroy(): void {
    this.notesClickSubscription.unsubscribe();
    this.wsStatusSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.showSpinner = true;
    // this.userInfo = mockUserInfo();
    this.userInfo = getUserInfo();
    this.loadProcessor();
    // tslint:disable-next-line:no-string-literal
    this.calloutName = this.userInfo.basicInfo.firstName;
    this.isFileUtilityOpen = false;
    selectedTaskId.subscribe((id: string) => {
      this.selectedTaskId = id;
      if (this.selectedTaskId == null && this.toggleIconName === 'menu_right') {
        hideSidebar();
        this.toggleIconName = 'menu_left';
      }
    });
    hideSidebar();
    this.showSpinner = false;
    this.notesClickSubscription = notesClickEvent.subscribe((taskId: string) => {
      showSidebar();
      this.toggleIconName = 'menu_right';
      this.cdref.detectChanges();
    });
    this.wsStatusSubscription = wsStatus$.subscribe((event: { status: string }) => {
      if (event.status.toUpperCase() === "CONNECTED") {
        this.sendwsMessage();
      }
    });
  }

  loadProcessor() {
    const userId = this.userInfo.id;
    this.processorService.getAllUserProcess(userId).then(res => {
      const data: ProcessDataModel[] = res;
      this.processorList = data.sort((a,b) => a.processName.localeCompare(b.processName));
      if (!!sessionStorage.getItem("ActiveProcessId") && this.processorList.some(x => x.processID === sessionStorage.getItem("ActiveProcessId"))) {
        this.selectedProcessId = sessionStorage.getItem("ActiveProcessId") as string;
      }
      else {
        const defaultProcess: ProcessDataModel = this.processorList.find(x => x.isDefault === true) as ProcessDataModel;
        this.selectedProcessId = defaultProcess?.processID!;
        sessionStorage.setItem("ActiveProcessId", this.selectedProcessId);
      }
      this.sendwsMessage();
      this.emitProcess();
    }, (error: HttpErrorResponse) => {
      const err: ErrorModel = {
        applicationName: 'IGPS-Client-Dashboard-Header',
        error: error.message,
        loggedInUserName: getUser(),
        url: error.url!
      };
      this.loggerService.logApplicationErrors(err);
      this.openToast(CommonMessages.IGPSApiServerErrorMessage, true);
    });
  }

  toggleFileUtility() {
    const selectedProcess = this.processorList.find(x => x.processID === this.selectedProcessId) as ProcessDataModel;
    if (this.selectedTaskId) {
      if (this.isFileUtilityOpen) {
        this.toggleIconName = 'menu_left';
        this.isFileUtilityOpen = false;
      } else {
        this.toggleIconName = 'menu_right';
        this.isFileUtilityOpen = true;
      }
    }
    else if (selectedProcess.taskSource === 2) {
      this.openToast('Select an email below or start a new entry');
    } else {
      this.openToast('Select a task below or start new task');
    }

  }

  togglePanel() {
    const selectedProcess = this.processorList.find(x => x.processID === this.selectedProcessId) as ProcessDataModel;
    if (isSidebarOpen()) {
      hideSidebar();
      this.toggleIconName = 'menu_left';
    } else {
      if (this.selectedTaskId !== null) {
        showSidebar();
        this.toggleIconName = 'menu_right';
      } else if (selectedProcess.taskSource === 2) {
        this.openToast('Select an email below or start a new entry');
      } else {
        this.openToast('Select a task below or start new task');
      }
    }
  }

  private sendwsMessage() {
    const selectedProcess = this.processorList.find(x => x.processID === this.selectedProcessId) as ProcessDataModel;
    if(selectedProcess){
      const payload: Payload = {
        activePage: selectedProcess.taskSource === 1 ? "taskdashboard" : "emaildashboard",
        processId: this.selectedProcessId
      };
      ws_sendMessage(payload);
    }
  }

  onSelectionChange(event: any) {
    this.emitProcess();
  }

  emitProcess() {
    this.sendwsMessage();
    sessionStorage.setItem("ActiveProcessId", this.selectedProcessId);
    const selectedProcess = this.processorList.find(x => x.processID === this.selectedProcessId) as ProcessDataModel;
    onChangeProcess.emit({ id: selectedProcess.processID, name: selectedProcess.processName, processorAddTask: selectedProcess.processorAddTask, taskSource: selectedProcess.taskSource });
  }

  openToast(message: string, isError: boolean = false) {
    this.toaster.toastMessage = message;
    this.toaster.actionRightText = 'close';
    this.toaster.showToast = true;
    this.toaster.error = isError;
    this.toaster.toasterTimeout();
    setTimeout(() => {
      this.cdref.detectChanges();
    }, 5100);
  }
}
